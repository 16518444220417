import * as Sentry from "@sentry/browser";

if (window.sentry_dsn) {
    Sentry.init({
        dsn: window.sentry_dsn,
        release: window.app_release,
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: 0.01,
        tracePropagationTargets: [
            /^https:\/\/www.luxplus\.be\//,
            /^https:\/\/www.luxplus\.co\.uk\//,
            /^https:\/\/www.luxplus\.dk\//,
            /^https:\/\/www.luxplus\.fi\//,
            /^https:\/\/www.luxplus\.nl\//,
            /^https:\/\/www.luxplus\.no\//,
            /^https:\/\/www.luxplus\.se\//,
        ],
        ignoreErrors: ["Non-Error promise rejection captured.+"],
    });
}
