$(".address-form input").on("input", function () {
    if ($(this)[0].checkValidity()) {
        $(".save-address").removeClass("disabled");
        $(this)
            .parents(".form-group")
            .find(".pattern-error")
            .addClass("d-none");
    } else {
        $(".save-address").addClass("disabled");
    }
});

$(".input-pattern").change(function () {
    if (!$(this)[0].checkValidity()) {
        $(this)
            .parents(".form-group")
            .find(".pattern-error")
            .removeClass("d-none");
    }
});

function containsUppercase(str) {
    const uppercasePattern = /\p{Lu}/u;
    return uppercasePattern.test(str);
}

function containsLowercase(str) {
    const lowercasePattern = /\p{Ll}/u;
    return lowercasePattern.test(str);
}

function containsNumber(str) {
    return /[0-9]/.test(str);
}

$(".password-check-validity").on("input", function () {
    const inputValue = $(this)[0].value;

    if (inputValue.length >= 8) {
        $(".password-length").addClass("accepted");
    } else {
        $(".password-length").removeClass("accepted");
    }

    if (containsUppercase(inputValue)) {
        $(".password-uppercase").addClass("accepted");
    } else {
        $(".password-uppercase").removeClass("accepted");
    }

    if (containsLowercase(inputValue)) {
        $(".password-lowercase").addClass("accepted");
    } else {
        $(".password-lowercase").removeClass("accepted");
    }

    if (containsNumber(inputValue)) {
        $(".password-number").addClass("accepted");
    } else {
        $(".password-number").removeClass("accepted");
    }
});

const validation = {
    showPassword(e) {
        var passwordInput = e
            .closest(".lux-password-wrapper")
            .querySelector("input");

        if (passwordInput.getAttribute("type") === "password") {
            passwordInput.setAttribute("type", "text");
            e.classList.add("active");
        } else {
            passwordInput.setAttribute("type", "password");
            e.classList.remove("active");
        }
    },
};

window.validation = validation;

if (import.meta.hot) {
    import.meta.hot.accept();
}
